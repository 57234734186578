import { z } from 'zod';

export const passwordSchema = z
  .string({ required_error: 'Password is required' })
  .min(6, { message: 'Password is too short' })
  .max(100, { message: 'Password is too long' })
  .transform((x) => x.trim());

export const nameSchema = z
  .string({ required_error: 'Name is required' })
  .min(1, { message: 'Name is too short' })
  .max(40, { message: 'Name is too long' })
  .transform((x) => x.trim());

export const emailSignupSchema = z
  .string({ required_error: 'Email is required' })
  .email({ message: 'Email is invalid' })
  .min(3, { message: 'Email is too short' })
  .max(100, { message: 'Email is too long' });

export const emailSchema = z
  .string({ required_error: 'Email is required' })
  .email({ message: 'Email is invalid' })
  .min(3, { message: 'Email is too short' })
  .max(100, { message: 'Email is too long' })
  // users can type the email in any case, but we store it in lowercase
  .transform((value) => value.toLowerCase());

export const otpSchema = z
  .string({ required_error: 'Code is required' })
  .min(6, { message: 'Code must consist of 6 digits' })
  .max(6, { message: 'Code must consist of 6 digits' })
  .transform((value) => value.trim());
